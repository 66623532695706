/* eslint-disable @typescript-eslint/no-unused-vars */
import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import userPluginsReducer from './userplugins';
import queueReducer from './queue';
import billingReducer from './billing';
import storeFiltersReducer from './storeFilters';
import workspaceProjectReducer from './workspaceProject';
import projectReducer, { fetchData } from './projectSlice';
import queueDataReducer from './queuedata'
import wpPluginDataReducer from './getWpPluginData'
import userDetailsSlice from './userDetailsSlice';
import getSupportCategoryReducer from './getSupportCategory'
import getSupportStatusReducer from './getSupportStatus'
import getSupportCustomerReducer from './getSupportCustomer'
import getSupportTicketsReducer from './getSupportTickets'
import getSupportTicketsThreadReducer from './getSupportTicketThread'
import getSupportTicketDetails from './getSupportTicketDetails'
import getNotification from './getNotificationList'
import getDeployData from './getDeployData'
import syncWpEnvironments from './syncWpEnvironment'
import introData from './introArray'
import envChangesData from './getEnvironmentChanges'
import numberSlice from './notifyNumbers'
import getSSHData from './getSSHDetails'

// ...

export const store = configureStore({
  reducer: {
    api: api.reducer,
    queue: queueReducer,
    billing: billingReducer,
    storeFilters: storeFiltersReducer,
    workspaceProject: workspaceProjectReducer,
    userplugins: userPluginsReducer,
    projects: projectReducer,
    queueData:queueDataReducer,
    wpData:wpPluginDataReducer,
    userDetails:userDetailsSlice,
    supportStatus:getSupportStatusReducer,
    supportCategory:getSupportCategoryReducer,
    supportCustomers:getSupportCustomerReducer,
    supportTickets:getSupportTicketsReducer,
    supportTicketThread:getSupportTicketsThreadReducer,
    supportTicketDetails:getSupportTicketDetails,
    getNotification:getNotification,
    deployData:getDeployData,
    syncWpEnvironments:syncWpEnvironments,
    introArray:introData,
    envChangesData:envChangesData,
    notifyNumber:numberSlice,
    getSSHData:getSSHData
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  devTools: true,
});

// store.dispatch(fetchData())

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

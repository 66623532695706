import { createApi } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';
const axiosBaseQuery =
  (
    { baseUrl } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  }> =>
  async ({ url, method, data }) => {
    data = Object.assign({ fp_api_key: process.env.REACT_APP_FP_API_KEY }, data);
    try {
      const result = await axios({
        baseURL: baseUrl,
        url,
        method,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // if (result.data.error) {
      //   return {
      //     error: { status: result.data.status, message: result.data.message },
      //   };
      // }

      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, message: err.response?.data },
      };
    }
  };

export const api = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/`,
  }),
  tagTypes: ['Post', 'Session'],
  endpoints: builder => ({
    store: builder.query({
      query: args => ({
        url: args.type,
        method: 'post',
        data: { search: args.search, ...args.data },
      }),
      providesTags: ['Post'],
    }),
    auth: builder.query({
      query: args => ({
        url: args.type,
        method: 'post',
        data: args.data,
      }),
    }),

    // Company routes
    company: builder.query({
      query: data => ({
        url: 'companies',
        method: 'post',
        data,
      }),
    }),
    putCompany: builder.mutation({
      query: data => ({
        url: 'company',
        method: 'put',
        data,
      }),
    }),
    patchCompany: builder.mutation({
      query: ({ id, data }) => ({
        url: 'company/' + id,
        method: 'patch',
        data,
      }),
    }),
    deleteCompany: builder.mutation({
      query: id => ({
        url: 'company/' + id,
        method: 'delete',
      }),
    }),

    // Team routes
    team: builder.query({
      query: data => ({
        url: 'teams',
        method: 'post',
        data,
      }),
    }),
    putTeam: builder.mutation({
      query: ({ id, data }) => ({
        url: 'company/' + id + '/team',
        method: 'put',
        data,
      }),
    }),
    patchTeam: builder.mutation({
      query: ({ id, data }) => ({
        url: 'team/' + id,
        method: 'patch',
        data,
      }),
    }),
    deleteTeam: builder.mutation({
      query: id => ({
        url: 'team/' + id,
        method: 'delete',
      }),
    }),

    putTeamUser: builder.mutation({
      query: ({ team, user }) => ({
        url: 'team/' + team + '/user/' + user,
        method: 'put',
      }),
    }),

    // Project routes
    project: builder.query({
      query: ({ id, data }) => ({
        url: id ? 'project/' + id : 'projects',
        method: 'post',
        data,
      }),
      providesTags: ['Post'],
    }),
    putProject: builder.mutation({
      query: ({ id, data }) => ({
        url: 'team/' + id + '/project',
        method: 'put',
        data,
      }),
      invalidatesTags: ['Post'],
    }),
    patchProject: builder.mutation({
      query: ({ id, data }) => ({
        url: 'project/' + id,
        method: 'patch',
        data,
      }),
    }),
    deleteProject: builder.mutation({
      query: id => ({
        url: 'project/' + id,
        method: 'delete',
      }),
    }),

    // Project billing routes
    projectBilling: builder.query({
      query: ({ id, data }) => ({
        url: 'project/' + id + '/billing',
        method: 'post',
        data,
      }),
    }),

    // Project plugin routes
    syncProjectPlugins: builder.mutation({
      query: ({ project, plugins }) => ({
        url: `project/${project}/plugins`,
        method: 'patch',
        data: { plugins },
      }),
    }),

    // User routes

    userProfile: builder.query({
      query: ({ id, data }) => ({
        url: 'user/' + id,
        method: 'post',
        data,
      }),
    }),

    // User plugin routes
    userPlugins: builder.query({
      query: ({ id, data }) => ({
        url: 'plugins/user',
        method: 'post',
        data,
      }),
      providesTags: ['Post'],
    }),
    syncUserPlugins: builder.mutation({
      query: data => ({
        url: 'plugins/addremove',
        method: 'post',
        data,
      }),
      invalidatesTags: ['Post'],
    }),

    syncUserPluginsProjects: builder.mutation({
      query: data => ({
        url: 'projects/user',
        method: 'post',
        data,
      }),
    }),

    //user session router
    userSession: builder.query({
      query: () => ({
        url: 'plugins/session',
        method: 'post',
      }),
      providesTags: ['Session'],
    }),
    userSessionMutation: builder.mutation({
      query: data => ({
        url: 'plugins/sessionStore',
        method: 'post',
        data,
      }),
      invalidatesTags: ['Session'],
    }),

    login: builder.mutation({
      query: data => ({
        url: 'login',
        method: 'post',
        data,
      }),
    }),
    register: builder.mutation({
      query: data => ({
        url: 'register',
        method: 'post',
        data: data,
      }),
    }),
    //auth router
    authentication: builder.mutation({
      query: data => ({
        url: 'authOtp',
        method: 'post',
        data: data,
      }),
    }),
    checkUser: builder.mutation({
      query: data => ({
        url: 'checkuser',
        method: 'post',
        data: data,
      }),
    }),

    //get all plugin
    allPlugin: builder.query({
      query: data => ({
        url: 'allplugins',
        method: 'post',
        data,
      }),
    }),

    //composerUpdate
    updateComposer: builder.mutation({
      query: data => ({
        url: 'projects/environment',
        method: 'post',
        data,
      }),
    }),

    addQueue: builder.mutation({
      query: data => ({
        url: 'composer/addqueue',
        method: 'post',
        data,
      }),
      invalidatesTags: ['Post'],
    }),

    updateProject: builder.mutation({
      query: data => ({
        url: 'project/updateProject',
        method: 'post',
        data,
      }),
    }),

    pluginData: builder.query({
      query: data => ({
        url: 'getPluginData',
        method: 'post',
        data: { plugins: data },
      }),
    }),

    feedback: builder.mutation({
      query: data => ({
        url: 'feebackform',
        method: 'post',
        data,
      }),
    }),

    forgotPass: builder.mutation({
      query: data => ({
        url: 'forgotpassword',
        method: 'post',
        data,
      }),
    }),

    updatePass: builder.mutation({
      query: data => ({
        url: `updatepassword`,
        method: 'post',
        data: data,
      }),
    }),

    userCreate: builder.mutation({
      query: data => ({
        url: `userCreate`,
        method: 'post',
        data: data,
      }),
    }),
    userDetails: builder.query({
      query: data => ({
        url: `getUserDetails`,
        method: 'post',
        data: data,
      }),
    }),
    addEnviornmentQueue: builder.mutation({
      query: data => ({
        url: `addEnviornmentQueue`,
        method: 'post',
        data: data,
      }),
    }),
    getQueueDataByUser: builder.query({
      query: data => ({
        url: `getQueueDataByUser`,
        method: 'post',
        data: data,
      }),
    }),
    getDeployQueueData: builder.query({
      query: data => ({
        url: `getDeployQueueData`,
        method: 'post',
        data: data,
      }),
    }),
    getUserWisePlugin: builder.query({
      query: data => ({
        url: `getUserWisePlugin`,
        method: 'post',
        data: data,
      }),
    }),
    getDataByPlugin: builder.query({
      query: data => ({
        url: `getDataByPlugin`,
        method: 'post',
        data: data,
      }),
    }),
    getDeploySSH: builder.mutation({
      query: data => ({
        url: `deploySSH`,
        method: 'post',
        data: data,
      }),
    }),
    updateOtpPreference: builder.mutation({
      query: data => ({
        url: `updateOtpPreference`,
        method: 'post',
        data: data,
      }),
    }),
    storeWpPlugin: builder.mutation({
      query: data => ({
        url: `storeWpPlugin`,
        method: 'post',
        data: data,
      }),
    }),
    userComposer: builder.mutation({
      query: data => ({
        url: `userComposer`,
        method: 'post',
        data: data,
      }),
    }),
    getPluginsByEnv: builder.query({
      query: data => ({
        url: `getPluginsByEnv`,
        method: 'post',
        data: data,
      }),
    }),
    editProject: builder.mutation({
      query: ({id,data}) => ({
        url: `project/${id}`,
        method: 'patch',
        data: data,
      }),
    }),
    getUserComposer: builder.query({
      query: (data) => ({
        url: `getUserComposer`,
        method: 'post',
        data: data,
      }),
    }),
    deleteQueueData: builder.mutation({
      query: (data) => ({
        url: `deleteQueueData`,
        method: 'post',
        data: data,
      }),
    }),
    addWpPlugin: builder.mutation({
      query: (data) => ({
        url: `addWpPlugin`,
        method: 'post',
        data: data,
      }),
    }),
    wordpressPluginSync: builder.mutation({
      query: (data) => ({
        url: `wordpress-plugin`,
        method: 'post',
        data: data,
      }),
    }),
    pluginOperation: builder.mutation({
      query: (data) => ({
        url: `plugin-operation`,
        method: 'post',
        data: data,
      }),
    }),
    envDelete: builder.mutation({
      query: (id) => ({
        url: `env/${id}`,
        method: 'delete',
        data: {},
      }),
    }),
    updateEnvQueueVersion:builder.mutation({
      query: (data) => ({
        url: `updateEnvQueueVersion`,
        method: 'post',
        data: data,
      }),
    }),
    environmentChange:builder.mutation({
      query:(data) => ({
        url:'EnvironmentChange',
        method:'post',
        data:data
      })
    }),
    addRemoveWishlistProject:builder.mutation({
      query:(data) => ({
        url:'addRemoveWishlistProject',
        method:'post',
        data:data
      })
    }),
    addSSHDetail:builder.mutation({
      query:(data) => ({
        url:'addSSHDetail',
        method:'post',
        data:data
      })
    })
  }),
});

export const {
  useStoreQuery,
  useAuthQuery,

  useCompanyQuery,
  usePutCompanyMutation,
  usePatchCompanyMutation,
  useDeleteCompanyMutation,

  useTeamQuery,
  usePutTeamMutation,
  usePatchTeamMutation,
  useDeleteTeamMutation,

  usePutTeamUserMutation,

  useProjectQuery,

  usePutProjectMutation,
  usePatchProjectMutation,
  useDeleteProjectMutation,

  useProjectBillingQuery,
  useSyncProjectPluginsMutation,
  useUserProfileQuery,
  useUserPluginsQuery,
  useSyncUserPluginsMutation,
  useSyncUserPluginsProjectsMutation,

  useUserSessionQuery,
  useUserSessionMutationMutation,

  useAuthenticationMutation,
  useLoginMutation,
  useRegisterMutation,
  useCheckUserMutation,
  useAllPluginQuery,
  useUpdateComposerMutation,
  useAddQueueMutation,
  useUpdateProjectMutation,
  usePluginDataQuery,
  useFeedbackMutation,
  useForgotPassMutation,
  useUpdatePassMutation,
  useUserCreateMutation,
  useUserDetailsQuery,
  useAddEnviornmentQueueMutation,
  useGetQueueDataByUserQuery,
  useGetDeployQueueDataQuery,
  useGetUserWisePluginQuery,
  useGetDataByPluginQuery,
  useGetDeploySSHMutation,
  useUpdateOtpPreferenceMutation,
  useStoreWpPluginMutation,
  useUserComposerMutation,
  useGetPluginsByEnvQuery,
  useEditProjectMutation,
  useGetUserComposerQuery,
  useDeleteQueueDataMutation,
  useAddWpPluginMutation,
  useWordpressPluginSyncMutation,
  usePluginOperationMutation,
  useEnvDeleteMutation,
  useUpdateEnvQueueVersionMutation,
  useEnvironmentChangeMutation,

  useAddRemoveWishlistProjectMutation,
  useAddSSHDetailMutation
} = api;

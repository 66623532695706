/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSelector, useAppDispatch } from '../features/hooks';
import { Iitem } from '../Interface/appInterface';

import { useState, useEffect } from 'react';
import { useAddEnviornmentQueueMutation, useProjectQuery, useStoreQuery } from '../features/api';
import { setPage, setSort, setOrder, setDataType, setProjectType, setPerpage, setType, setFlag } from '../features/storeFilters';

import PaginatedList from '../components/PaginatedList';
import StorePlugin from '../components/StorePlugin';
import StoreTheme from '../components/StoreTheme';
import SelectedPlugin from './SelectedPlugin';
import classNames from 'classnames';
import Loader from './Loader';
import { Steps } from 'intro.js-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faQuestion, faSmile, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CommonModal from './CommonModal';
import { useDeleteQueryString, useManageQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
import { updateTrigger } from '../features/introArray';

const StoreList:React.FC = () => {
  const [view, setView] = useState('grid');
  const {t, i18n} = useTranslation()

  const handleClick = (mode: string) => {
    setView(mode);
  };
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.storeFilters.value);

  const { data, error, isLoading, isError } = useStoreQuery<any>({
    type: filters.type,
    search: filters.search,
    // data: filters.data,
    data:{
      ...filters.data,
      tag:filters.data.tag.length > 0? filters.data.tag.split(','):"",
      vendor:filters.data.vendor.length > 0? filters.data.vendor.split(','):""
    }
  });
  console.log("filters",filters)
  const pathname = window.location.pathname;
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const queryString = new URLSearchParams(window.location.search);
  const [projectData, setProjectData] = useState<any>({});
  const storePlugins = localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
  const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);
  const [selectThemeData, setSelectThemeData] = useState<Array<any>>([]);
  const [show, setShow] = useState(false);
  const [envP, setEnvP] = useState<any>('');
  const navigate = useNavigate();
  const { data: projectsList } = useProjectQuery({
    data: { 'with-plugins': true },
  });
  const handleClose = () => setShow(false);
  const [addEnviornmentQueue] = useAddEnviornmentQueueMutation();
  const [selectMeta, setSelectMeta] = useState<any>({});
  // const introQuery = new URLSearchParams(window.location.search)
  const location = useLocation();
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  const manageQueryString = useManageQueryString();

  const introArray = useAppSelector((state) => state.introArray.arrayData)
  console.log("introArray",introArray)

  useEffect(() => {
    console.log("introArray",introArray)
    if(introArray.length > 0){
      const getspecificdata = introArray.find((ik) => ik.page === "store")
      if(getspecificdata !== undefined){
        if(getspecificdata.trigger === true){
          setStepsEnabled(true)
        }
      }
    }

  },[introArray])

  // useEffect(() => {

  //   console.log('hello',location.search)
  //   if(location.search.length > 0){
  //     if(queryString.get("intro")){
  //       setStepsEnabled(true)
  //     }
  //   }

  // },[location.search])
  let initialSteps = 0;
  let StoreSteps = [
    {
      title: t('intro.store.first.title'),
      // element: ".store-main-first",
      position: 'center',
      intro: t('intro.store.first.desc'),
    },
    {
      title: t('intro.store.two.title'),
      element: '.store-first',
      position: 'left',
      intro: t('intro.store.two.desc'),
    },
    {
      title: t('intro.store.three.title'),
      element: '.store-second',
      position: 'left',
      intro: t('intro.store.three.desc'),
    },
  ];

  const userPlugins = useAppSelector((state: any) => state.userplugins.value);
  const isSelected = (id: Number) => {
    for (const plugin of userPlugins.plugins) {
      if (plugin.id === Number(id)) return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      queryString.get('project') !== null &&
      queryString.get('env') !== null &&
      queryString.get('redirect') !== null
    ) {
      if (queryString.get('project') !== '' && queryString.get('env') !== '' && queryString.get('redirect') !== '') {
        dispatch(
          setProjectType({
            project: queryString.get('project'),
            env: queryString.get('env'),
            redirect: queryString.get('redirect'),
          })
        );
      }
    }
  }, [queryString.get('project'), queryString.get('env'), queryString.get('redirect')]);

  useEffect(() => {
    if (typeof projectsList !== 'undefined') {
      if (
        queryString.get('project_id') !== null &&
        queryString.get('env_id') !== null &&
        queryString.get('redirect_to') !== null
      ) {
        if (
          queryString.get('project_id') !== '' &&
          queryString.get('env_id') !== '' &&
          queryString.get('redirect_to') !== ''
        ) {
          const projectdata = projectsList.projects.find((i: any) => {
            return i.id === parseInt(queryString.get('project_id') as any);
          });
          if (projectdata !== undefined) {
            setProjectData(projectdata);
            const meta = projectdata.meta.find((k: any) => {
              return k.meta.id === parseInt(queryString.get('env_id') as any);
            });
            if (meta !== undefined) {
              setEnvP(`${meta.meta.id}`);
            }
          }
        }
      }
    }
  }, [queryString.get('project_id'), queryString.get('env_id'), queryString.get('redirect_to'), projectsList]);

  const changeOrder = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    // dispatch(setOrder(event.target.value))
    dispatch(setSort(event.target.value));
  };
  const setPluginType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDataType(event.target.value));
  };

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'store-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'store-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'store-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    console.log("hello")
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'store-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'store-screen';
      });
      // if(index < 0){
      //    const homeScreen = {screen:"home-screen", status:'false'}
      //     array.push(homeScreen)
      //     setStepsEnabled(
      //       homeScreen.status === 'true' ? true : false
      //     );
      //     localStorage.setItem("introjs",JSON.stringify(array!))
      // }
      if (index >= 0) {
        array[index].status = 'false';
        setStepsEnabled(array[index].status === 'false' ? false : true);
        // manageQueryString(array[index].status)
        dispatch(updateTrigger({page: 'store', trigger: false}))
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };
  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'store-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  const navigateToProject = (): void => {
    // navigate(link)

    setShow(true);
    // navigate(queryString.get("redirect_to") as any)
  };
  const saveAndGoBack = (): void => {
    const data1 = selectPluginData.map((i: any) => {
      const sortedVersions =
        i.versions.length > 0
          ? [...i.versions].sort((a: any, b: any) => {
              const aParts = a.version.split('.').map(Number);
              const bParts = b.version.split('.').map(Number);

              // Compare each segment of the version
              for (let i = 0; i < 3; i++) {
                if (aParts[i] !== bParts[i]) {
                  return aParts[i] - bParts[i];
                }
              }

              return 0;
            })
          : [];

      const latestVersion = sortedVersions.length > 0 ? sortedVersions[sortedVersions.length - 1].version : '';
      return {
        plugin_slug: i.slug,
        plugin_version: latestVersion,
        env_id: parseInt(envP),
        project_id: parseInt(queryString.get('project_id') as any),
        flag: 'add_from_store',
        version: latestVersion,
      };
    });
    addEnviornmentQueue({ data: data1 }).then((ik: any) => {
      if (ik.error) {
        toast.error(ik.error.message.message);
      } else {
        toast.success(ik.data.message);
        navigate(queryString.get('redirect_to') as any);
      }
    });
  };

  const saveProject = () => {
    const data1 = selectPluginData.map((i: any) => {
      const sortedVersions =
        i.versions.length > 0
          ? [...i.versions].sort((a: any, b: any) => {
              const aParts = a.version.split('.').map(Number);
              const bParts = b.version.split('.').map(Number);

              // Compare each segment of the version
              for (let i = 0; i < 3; i++) {
                if (aParts[i] !== bParts[i]) {
                  return aParts[i] - bParts[i];
                }
              }

              return 0;
            })
          : [];

      const latestVersion = sortedVersions.length > 0 ? sortedVersions[sortedVersions.length - 1].version : '';
      return {
        plugin_slug: i.slug,
        plugin_version: latestVersion,
        env_id: parseInt(envP),
        project_id: parseInt(selectMeta.id),
        flag: 'add_from_store',
        version: latestVersion,
      };
    });
    addEnviornmentQueue({ data: data1 }).then((ik: any) => {
      if (ik.error) {
        toast.error(ik.error.message.message);
      } else {
        toast.success(ik.data.message);
        setShow(false);
        setSelectPluginData([]);
      }
    });
  };
  const cancelAndGoBack = (): void => {
    navigate(queryString.get('redirect_to') as any);
  };

  const selectProject = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const getProject = projectsList.projects.find((ik: any) => {
      return ik.id === parseInt(event.target.value);
    });
    console.log('getProject', getProject);
    if (getProject !== undefined) {
      if (Object.prototype.hasOwnProperty.call(getProject, 'meta')) {
        setSelectMeta(getProject);
      }
    }
  };
  console.log('selectMeta', selectMeta);

  // useEffect(() => {
  //   dispatch(setPage(1))
  // },[])

  const AddData = (): void => {
    dispatch(setPage(parseInt(queryString.get('pg') as any) + 1));
    // setAllPlugins([...allPlugins,data.items])
  };
  const clearStoreQueue = (): void => {
    setSelectPluginData([]);
    localStorage.removeItem('storequeue');
  };

  const redirectStoreQueue = (): void => {
    navigate('/queue');
  };

  const [showContainer, setShowContainer] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShowContainer(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleContainer = () => {
    setShowContainer(!showContainer);
  };
  const closeContainer = ()=>{
    setShowContainer(false)
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }
  return (
    <>
      {pathname === '/store' && (
        <Steps
          enabled={stepsEnabled}
          steps={StoreSteps}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
          }}


        />
      )}

      <main className='main main-side'>
        <div className='container-fluid'>
          <div className='store_listab'>
            <ul className='nav nav-tabs store-first store-first'>
              <li className='nav-item m-0' >
                <button
                  className={filters.data.flag ==='plugins'?'nav-link active':'nav-link'}
                  type='button'
                  // id='all_plugins-tab'
                  // data-bs-toggle='tab'
                  // data-bs-target='#all_plugins'
                  // type='button'
                  // role='tab'
                  // aria-controls='all_plugins'
                  // aria-selected='true'
                  onClick={() =>  dispatch(setFlag('plugins'))}
                >
                  {t('store-list.All-Plugins')}
                </button>
              </li>
              <li className='nav-item m-0' role='presentation'>
                <button
                  className={filters.data.flag ==='theme'?'nav-link active':'nav-link'}
                  // id='all_themes-tab'
                  // data-bs-toggle='tab'
                  // data-bs-target='#all_themes'
                  type='button'
                  // role='tab'
                  // aria-controls='all_themes'
                  // aria-selected='true'
                  onClick={() =>  dispatch(setFlag('theme'))}
                >
                  Themes
                </button>
              </li>

              <div className='select_filter_group ms-auto'>
                <div className='btn-group flex-wrap filter_plugin_group' role='group' aria-label='Basic example'>

                  <button type='button' className='roundedButton' style={{marginBottom:'5px'}} onClick={() => onActivateIntro()}>
                    <FontAwesomeIcon icon={faQuestion} />
                  </button>
                </div>
              </div>
            </ul>
            <div>
            <div className='list_header'>
                  <div className='row align-items-center '>
                    <div className='col-md-12 store-second'>
                      {Object.keys(projectData).length > 0 ? (
                        <>
                          <div className='environment_select_redirect_btn'>
                            <div className='select_filter_group'>
                            </div>
                          </div>
                          <div className='row align-items-center total_plug_sort'>

                            <div className='col-md-6'>
                              <div className='text-end d-flex justify-content-end align-items-center sort_filter'>
                                <span className='text-white me-2 sorttxt' style={{textWrap:'nowrap'}}>{t('store-list.Sort-by')}</span>
                                <select className='form-select w-auto selectbox' onChange={e => changeOrder(e)}>
                                  <option value=''>{t('store-list.Recent-View')}</option>

                                  <option value='time'>{t('store-list.Newest')}</option>
                                  <option value='ratings'>{t('store-list.Best-Rated')}</option>
                                  <option value='price'>{t('store-list.Price')}</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-md-3'>
                              <div className='d-flex align-items-center'>
                                <div className='number'>
                                <span>{data.count.toLocaleString()} Hello</span>
                                <div>{t('store-list.items-in-wordpress-plugin')}</div>
                                </div>
                                <select
                                  className='form-select w-auto selectbox'
                                  value={queryString.get('perpage') as any}
                                  onChange={e => dispatch(setPerpage(parseInt(e.target.value)))}
                                >
                                  <option value='0'>{t('store-list.Select-number-of-plugin-to-show')}</option>
                                  <option value='20'>20</option>
                                  <option value='40'>40</option>
                                  <option value='60'>60</option>
                                  <option value='80'>80</option>
                                  <option value='100'>100</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='environment_select_redirect_btn'>
                          <div className='row align-items-center'>
                            <div className='col-md-12'>
                              <div className='text-end d-flex justify-content-between align-items-center sort_filter mt-3'>
                             <div className="total_plug_sort">
                             <div className='total-plug'>
                                <span>{data.count.toLocaleString()} </span>
                                <div>{t('store-list.items-in-wordpress-plugin')}</div>
                              </div>
                             <select
                                className='form-select w-auto selectbox'
                                onChange={e => dispatch(setPerpage(parseInt(e.target.value)))}
                              >
                                <option value='0'>{t('store-list.Select-number-of-plugin-to-show')}</option>
                                <option value='20'>20</option>
                                <option value='40'>40</option>
                                <option value='60'>60</option>
                                <option value='80'>80</option>
                                <option value='100'>100</option>
                              </select>
                             </div>
                            <div className='d-flex align-items-center'>
                            <span className='text-white me-2 sorttxt' style={{textWrap:'nowrap'}}>{t('store-list.Sort-by')}</span>
                                <select className='form-select w-auto selectbox' onChange={e => changeOrder(e)}>
                                  <option value=''>{t('store-list.Recent-View')}</option>

                                  <option value='time'>{t('store-list.Newest')}</option>
                                  <option value='ratings'>{t('store-list.Best-Rated')}</option>
                                  <option value='price'>{t('store-list.Price')}</option>
                                </select>
                                <div className='select_redirect_btn'>

                              <div className='ms-auto text-end'>
                                <span
                                  className={view === 'list' ? 'list_view me-2 selected' : 'list_view me-2'}
                                  onClick={e => handleClick('list')}
                                >
                                  <svg
                                    width='20'
                                    height='19'
                                    viewBox='0 0 20 19'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <circle cx='2.18299' cy='17.378' r='1.62195' fill='#D9D9D9' />
                                    <rect
                                      x='5.65869'
                                      y='15.7561'
                                      width='8.80488'
                                      height='3.2439'
                                      rx='1.62195'
                                      fill='#D9D9D9'
                                    />
                                    <circle cx='2.18299' cy='9.5' r='1.62195' fill='#D9D9D9' />
                                    <rect
                                      x='5.65869'
                                      y='7.87805'
                                      width='13.9024'
                                      height='3.2439'
                                      rx='1.62195'
                                      fill='#D9D9D9'
                                    />
                                    <circle cx='2.18299' cy='1.62195' r='1.62195' fill='#D9D9D9' />
                                    <rect x='5.65869' width='11.5854' height='3.2439' rx='1.62195' fill='#D9D9D9' />
                                  </svg>
                                </span>
                                <span
                                  className={view === 'grid' ? 'grid_view selected' : 'grid_view'}
                                  onClick={e => handleClick('grid')}
                                >
                                  <svg
                                    width='19'
                                    height='19'
                                    viewBox='0 0 19 19'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <circle
                                      cx='1.62195'
                                      cy='1.62195'
                                      r='1.62195'
                                      transform='rotate(90 1.62195 1.62195)'
                                      fill='#F08921'
                                    />
                                    <rect
                                      x='3.2439'
                                      y='5.09756'
                                      width='8.80488'
                                      height='3.2439'
                                      rx='1.62195'
                                      transform='rotate(90 3.2439 5.09756)'
                                      fill='#F08921'
                                    />
                                    <circle
                                      cx='9.49988'
                                      cy='1.62195'
                                      r='1.62195'
                                      transform='rotate(90 9.49988 1.62195)'
                                      fill='#F08921'
                                    />
                                    <rect
                                      x='11.1218'
                                      y='5.09756'
                                      width='13.9024'
                                      height='3.2439'
                                      rx='1.62195'
                                      transform='rotate(90 11.1218 5.09756)'
                                      fill='#F08921'
                                    />
                                    <circle
                                      cx='17.378'
                                      cy='1.62195'
                                      r='1.62195'
                                      transform='rotate(90 17.378 1.62195)'
                                      fill='#F08921'
                                    />
                                    <rect
                                      x='19'
                                      y='5.09756'
                                      width='11.5854'
                                      height='3.2439'
                                      rx='1.62195'
                                      transform='rotate(90 19 5.09756)'
                                      fill='#F08921'
                                    />
                                  </svg>
                                </span>

                              </div>
                            </div>
                            </div>

                              </div>
                            </div>
                          </div>
                          <div className='col-md-3'>
                          </div>
                        </div>
                      )}

                    </div>

                  </div>

                </div>
                 <PaginatedList
                  page={Number(filters.data.pg)}
                  setPage={(value: number) => dispatch(setPage(value))}
                  pages={data.pages}
                >
                  <div className='container-fluid'>
                    <div className='row plugin_row'>
                      {filters.data.flag === 'plugins'
                        ? data.items.map((plugin: any) => {
                            const isSelectedActive =
                              selectPluginData.find((ik: any) => {
                                return ik.id === plugin.id;
                              }) !== undefined
                                ? true
                                : false;
                            return (

                              <StorePlugin
                                key={plugin.id}
                                plugin={plugin}
                                active={isSelected(Number(plugin.id))}
                                setSelectPluginData={setSelectPluginData}
                                selectPluginData={selectPluginData}
                                view={view}
                                isSelectedActive={isSelectedActive}
                              />

                            );
                          })
                        : data.items.map((theme: any) =>{
                          const isSelectedActive =
                          selectThemeData.find((ik: any) => {
                                return ik.id === theme.id;
                              }) !== undefined
                                ? true
                                : false;
                          return(
                            <StoreTheme key={theme.id} title={theme.name} theme={theme} view={view} isSelectedActive={isSelectedActive} />
                          )
                        })}
                    </div>
                  </div>
                </PaginatedList>
            </div>
            {/* <div className='tab-content'>
              <div className='tab-pane active' id='all_plugins' role='tabpanel' aria-labelledby='all_plugins-tab'>

              </div>
              <div className='tab-pane' id='selected_plugins' role='tabpanel' aria-labelledby='selected_plugins-tab'>

              </div>
            </div> */}
          </div>
        </div>
        {/* <CommonModal className='composer-model' show={show} onHide={handleClose} animation={false} size='lg'>
          <CommonModal.Header>
            <div>
              <h6 className='text-white'>{t('store-list.modals.Add-Plugin-to')}{projectData.name}</h6>
            </div>
          </CommonModal.Header>
          <CommonModal.Body>
            <div>
              <div className='row text-white'>
                <div className='col-md-6'>{t('store-list.modals.Plugin/Theme')}</div>
                <div className='col-md-4'>{t('store-list.modals.version')}</div>
                <div className='col-md-2'>{t('store-list.modals.Action')}</div>
              </div>

              {queryString.get('project_id') !== null ? (
                queryString.get('project_id') !== '' ? null : (
                  <div>
                    <div className='row my-3'>
                      <div className='col-md-4'>
                        {!Array.isArray(projectsList) && (
                          <>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              onChange={e => selectProject(e)}
                            >
                              <option value=''>{t('store-list.select')}</option>
                              {projectsList?.projects.map((iik: any) => {
                                return <option value={iik.id}>{iik.name}</option>;
                              })}
                            </select>
                          </>
                        )}
                      </div>
                      <div className='col-md-4'>
                        {Object.keys(selectMeta).length > 0 && (
                          <select
                            className='environment-select form-select w-auto bg-none'
                            onChange={e => setEnvP(e.target.value)}
                          >
                            <option value=''>{t('store-list.select')}</option>
                            {selectMeta.meta.map((ik: any) => {
                              return <option value={ik.meta.id}>{ik.meta.name}</option>;
                            })}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div>
                  <div className='row my-3'>
                    <div className='col-md-4'>
                      {!Array.isArray(projectsList) && (
                        <>
                          <select
                            className='environment-select form-select w-auto bg-none'
                            onChange={e => selectProject(e)}
                          >
                            <option value=''>{t('store-list.select')}</option>
                            {projectsList?.projects.map((iik: any) => {
                              return <option value={iik.id}>{iik.name}</option>;
                            })}
                          </select>
                        </>
                      )}
                    </div>
                    <div className='col-md-4'>
                      {Object.keys(selectMeta).length > 0 && (
                        <select
                          className='environment-select form-select w-auto bg-none'
                          onChange={e => setEnvP(e.target.value)}
                        >
                          <option value=''>{t('store-list.select')}</option>
                          {selectMeta.meta.map((ik: any) => {
                            return <option value={ik.meta.id}>{ik.meta.name}</option>;
                          })}
                        </select>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {selectPluginData.map((item: any) => {
                return (
                  <div className='row my-3 text-secondary' key={item.id}>
                    <div className='col-md-6'>{item.name}</div>
                    <div className='col-md-4'>
                      <select
                        className='environment-select form-select w-auto bg-none'
                        aria-label='Default select example'
                      >
                        {item.versions.length > 0 ? (
                          item.versions.map((v: any) => {
                            return (
                              <option key={v.id} value={v.version}>
                                {v.version}
                              </option>
                            );
                          })
                        ) : (
                          <option value='3'>{t('store-list.No')}</option>
                        )}
                      </select>
                    </div>
                    <div className='col-md-2'>
                    {t('store-list.add-to-project')}

                    </div>
                  </div>
                );
              })}
              {queryString.get('project_id') !== null ? (
                queryString.get('project_id') !== '' ? (
                  <div className='col-md-2 d-flex'>
                    <button
                      className='orange_btn inline-block'
                      onClick={() => saveAndGoBack()}
                      disabled={selectPluginData.length > 0 ? false : true}
                    >
                      {t('store-list.Save-and-Go-back-Project-Detail')}
                    </button>
                    <button className='orange_btn inline-block' onClick={() => cancelAndGoBack()}>
                    {t('store-list.Cancel-and-Go-back-Project-Detail')}
                    </button>
                  </div>
                ) : (
                  <div className='col-md-2 d-flex'>
                    <button
                      className='orange_btn inline-block'
                      onClick={() => saveProject()}
                      disabled={selectPluginData.length > 0 ? (envP !== '' ? false : true) : true}
                    >
                      {t('store-list.Save')}
                    </button>
                  </div>
                )
              ) : (
                <div className='col-md-2 d-flex'>
                  <button
                    className='orange_btn inline-block'
                    onClick={() => saveProject()}
                    disabled={selectPluginData.length > 0 ? (envP !== '' ? false : true) : true}
                  >
                    {t('store-list.Save')}
                  </button>
                </div>
              )}

            </div>
          </CommonModal.Body>
          <CommonModal.Footer>
            <button className='orange_btn' onClick={handleClose}>
            {t('store-list.Close')}
            </button>
          </CommonModal.Footer>
        </CommonModal> */}
      </main>
    </>
  );
}
export default StoreList;

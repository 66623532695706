import React, { SetStateAction } from 'react';
import { useAppSelector, useAppDispatch } from '../features/hooks';

import { toggleRatings } from '../features/storeFilters';

import Filter from '../components/Filter';
import CheckFilter from './CheckFilter';

export interface RatingInterface {
  ratingData: any;
  multiSelectArray:any
  setMultiSelectArray:React.Dispatch<SetStateAction<any>>
}

const Ratings:React.FC<RatingInterface> = (props: RatingInterface) =>  {
  // const ratingsOpts = [
  //   {
  //     value: "5",
  //     name: "4 star and higher",
  //   },
  //   {
  //     value: "4",
  //     name: "3 star and higher",
  //   },
  //   {
  //     value: "3",
  //     name: "2 star and higher",
  //   },
  //   {
  //     value: "2",
  //     name: "1 star and higher",
  //   },
  //   {
  //     value: "1",
  //     name: "Show all",
  //   },
  // ];

  const ratingsOpts =
    typeof props.ratingData === 'object' && props.ratingData !== null
      ? props.ratingData.ratings
          .sort((a:any, b:any) => parseInt(b.value) - parseInt(a.value))
      : [];
  console.log('ratingsOpts', ratingsOpts, props.ratingData);

  const ratings = useAppSelector(state => state.storeFilters.value.data.ratings);
  const dispatch = useAppDispatch();

  const toggleRate = (e:string) => {
    const ab= props.ratingData.ratings.map((ik:any) => {
      return {
        ...ik,
        checked:ik.value === e ? true: false
      }
    })
    props.setMultiSelectArray({
      ...props.multiSelectArray,
      ratings:ab
    })
  }

  return (
    <CheckFilter
      type='checkbox'
      title='Ratings'
      options={ratingsOpts}
      initial={ratings.length > 0 ? ratings[1] : '1'}
      // onchange={(value: string) => dispatch(toggleRatings(value))}
      onchange={(value: string) => toggleRate(value)}
    />
  );
}
export default Ratings;

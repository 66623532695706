/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useProjectQuery } from './api';

const initialState = {
  items: [],
  status: null,
};

export const getSSHDetails: any = createAsyncThunk('projects/getSSHDetails', async () => {
  // You can use useProjectQuery here to fetch data
  // console.log("useProjectQuery",useProjectQuery({'with-plugins':true}));
  const response = await axios({
    url: `${process.env.REACT_APP_API_URL}/api/getSSHDetail`,
    method: 'POST',
    data: {},
    headers: {
      // Accept: "multipart/form-data",
      // 'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
});

const productSlice = createSlice({
  name: 'getSSHDetails',
  initialState: {
    data: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getSSHDetails.pending]: (state: any, action: any) => {
      state.status = 'pending';
    },
    [getSSHDetails.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
    },
    [getSSHDetails.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
    },
  },
});

export default productSlice.reducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction } from 'react';
import { useAppDispatch, useAppSelector } from '../features/hooks';

import { setType, setTag } from '../features/storeFilters';

import Filter from '../components/Filter';
import CheckFilter from './CheckFilter';

export interface TypeInterface {
  typeData: any;
  multiSelectArray:any
  setMultiSelectArray:React.Dispatch<SetStateAction<any>>
}

const Type:React.FC<TypeInterface> = (props: TypeInterface) => {
  // const typeOpts = [
  //   {
  //     value: 'themes',
  //     name: 'Themes',
  //   },
  //   {
  //     value: 'plugins',
  //     name: 'Plugins',
  //   },
  // ];
console.log("props.typeData",props)
  const typeOpts =
    typeof props.typeData === 'object' && props.typeData !== null
      ? props.typeData.tag
          .map((value: any, index: number) => {
            return {
              value: value.id,
              name: value.tag,
              length: value.count,
              checked:value.checked
            };
          })
          .sort((a: any, b: any) => parseInt(b.value) - parseInt(a.value))
      : [];
     console.log("typeOpts",typeOpts)

  const tag = useAppSelector(state => state.storeFilters.value.data.tag);
  const dispatch = useAppDispatch();

  const setTags = (e:string) => {
    const ab = props.typeData.tag.map((ik:any) => {
      return{
        ...ik,
        checked: Number(ik.id) === Number(e) ? !ik.checked :ik.checked
      }
    })
    console.log(ab,"ab")
    props.setMultiSelectArray({
      ...props.multiSelectArray,
      tag:ab
    })
  }

  return (
    <CheckFilter
      type='checkbox'
      // title='Type'
      options={typeOpts}
      initial={tag}
      // onchange={(value: string) => dispatch(setTag(value))}
      onchange={(value: string) => setTags(value)}
    />
  );
}
export default Type;

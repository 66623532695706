import React, { SetStateAction } from 'react';
import { useAppSelector, useAppDispatch } from '../features/hooks';

import { setVendor } from '../features/storeFilters';

import Filter from '../components/Filter';
import CheckFilter from './CheckFilter';

export interface VendorInterface {
  vendorData: any;
  multiSelectArray:any
  setMultiSelectArray:React.Dispatch<SetStateAction<any>>
}

const Vendors:React.FC<VendorInterface> = (props: VendorInterface) => {
  // const vendorOpts = [
  //   {
  //     value: '1',
  //     name: 'Vendor 1',
  //   },
  //   {
  //     value: '2',
  //     name: 'Vendor 2',
  //   },
  //   {
  //     value: '3',
  //     name: 'Vendor 3',
  //   },
  //   {
  //     value: '4',
  //     name: 'Vendor 4',
  //   },
  // ];

  const vendorOpts =
    typeof props.vendorData === 'object' && props.vendorData !== null
      ? props.vendorData.vendor
          .map((value: any, index: number) => {
            return {
              value: value.author,
              name: value.author,
              length: value.count,
              checked:value.checked
            };
          })
          .sort((a: any, b: any) => parseInt(b.value) - parseInt(a.value))
      : [];

  const vendor = useAppSelector(state => state.storeFilters.value.data.vendor);
  const dispatch = useAppDispatch();

  const setVendors =(e:string) => {
    const ab = props.vendorData.vendor.map((ik:any) => {
      return{
        ...ik,
        checked: ik.author === e ? !ik.checked :ik.checked
      }
    })
    console.log(ab,"ab")
    props.setMultiSelectArray({
      ...props.multiSelectArray,
      vendor:ab
    })
  }

  return (
    <CheckFilter
      type='checkbox'
      title='Type'
      options={vendorOpts}
      initial={vendor}
      // onchange={(value: string) => dispatch(setVendor(value))}
      onchange={(value: string) => setVendors(value)}
    />
  );
}
export default Vendors;

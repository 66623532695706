/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import img from '../img/store-thumbnail.svg';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../features/hooks';
import { useDispatch } from 'react-redux';
import { setWishlistNumber } from '../features/notifyNumbers';

interface StoreThemeProps {
  title:any;
  theme:any;
  view:any;
  isSelectedActive:any;
}

const StoreTheme:React.FC<StoreThemeProps> =({ title,theme,view,isSelectedActive }: StoreThemeProps) => {
  console.log("theme",theme)
  const {t, i18n} = useTranslation()
  const [color, setColor] = useState<string>('')
  const [isFavorite, setIsFavorite] = useState(false);
  const filters = useAppSelector(state => state.storeFilters.value);
  const dispatch =useDispatch()
  const [wishlist1, setWishlist] = useState([]);
  const [src,setSrc] =useState(`https://i0.wp.com/themes.svn.wordpress.org/${theme.slug}/${theme.version}/screenshot.png`)
  console.log("theme",theme)
  const navigate=useNavigate()

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    setWishlist(wishlist)
    if (Array.isArray(wishlist)) {
      const isAlreadyInWishlist = wishlist.some((plugins:any) =>
      plugins.id === theme.id && plugins.dataType === filters.data.flag
      );
      if (isAlreadyInWishlist) {
        // setIsPluginInWishlist(true);
        setIsFavorite(true)
      }
    }
  },[theme, view]);
  useEffect(() => {

      dispatch(setWishlistNumber(wishlist1.length))


  },[wishlist1])

  const addToWishlist = () => {
    if(color === 'black'){
      setColor('F08921')
    }else{
      setColor('F08921')
    }
    console.log("pluginDetails",  theme);
    console.log("pluginDetailsfilter",  filters);
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    const isAlreadyInWishlist = wishlist.some((plugins:any) => plugins.id === theme.id && plugins.dataType===filters.data.flag);
    if (!isAlreadyInWishlist) {
      const newObj = {...theme, versions:[], dataType:filters.data.flag}
      wishlist.push(newObj);
      setWishlist(wishlist)
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      setIsFavorite(true)
      toast.success('Plugin Added to wishlist');
      console.log('Added to wishlist:', theme);

    } else {
      console.log("wishlist",wishlist)
      const filtered = wishlist.filter((ik:any) => {return !(ik.id === theme.id && ik.dataType===filters.data.flag)})
      setIsFavorite(false)
      setWishlist(filtered)
      localStorage.setItem('wishlist', JSON.stringify(filtered));
      toast.error('removed from wish list');
    }
    }
    const handleImageError = () => {
      setSrc(img)
   }

   const renderRating = () => {
    if (theme.type === 'private') {
      return (
        <div className='tooltip-container'>
          <div className='tooltip'>{t('store-list.plugin.Private')}</div>
        </div>
        //   {/* <div className="price">${plugin.rating}</div> */}
        //   <Rating tooltipDefaultText="Private" showTooltip={true} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" allowHover={false}/>
      );
    } else {
      return (
        <div aria-disabled={true}>
          {/* <div className="price">${plugin.rating}</div> */}
          <Rating
            initialValue={theme.ratings / 20}
            allowFraction={true}
            fillColor='#F28A03'
            size={20}
            className='float-start'
            emptyColor='#888888'
            allowHover={false}
            disableFillHover={true}
            readonly
          />
        </div>
      );
    }
  };
  const NavigateTo = (plugin:any) =>{
    navigate(`/store/${plugin.slug}`)
  }
  return (
  //   <div
  //   className={view === 'grid' ? 'col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns' : 'col-md-12 listmode plugin_columns'}
  // >
  //   <div className='plug_grid'>
  //     <div className='card-body'>
  //       <div className={view === 'grid' ? 'plugin_area' : 'plugin_area row g-3 '}>
  //         <div
  //           className={
  //             view === 'grid' ? 'plug_img' : 'plug_img col-sm-12 col-md-6 col-12  col-xl-3 order-md-0 order-xl-0'
  //           }
  //         >
  //           <div className='product_like' onClick={addToWishlist}>
  //               <FontAwesomeIcon icon={faHeart}  style={{color:color}} size='lg'/>
  //             </div>
  //           <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} alt='' onError={() => handleImageError()}></img>

  //         </div>
  //         <div
  //           className={view === 'grid' ? 'list' : 'list col-md-12 col-12 col-sm-12 col-xl-6 order-md-2 order-xl-1'}
  //         >
  //           <Link to={`/store/${theme.slug}`} style={{ textDecoration: 'none' }}>
  //           <div className='pluginname font20'>{title}</div>
  //           </Link>
  //           <div className='author font16'>{t('store-list.theme.By')} : {theme.wp_author}</div>
  //           <div className='up_date font16'>
  //             <span className='text-white font16'>{t('store-list.theme.Last-updated')} : </span>{' '}
  //             {moment(theme.updated_at).format('DD MMM YYYY') !== '01 Jan 0001'
  //               ? moment(theme.updated_at).format('DD MMM YYYY')
  //               : ''}
  //           </div>
  //           {view === 'list' ? (
  //             <div className='list_visible font16'>
  //               <div className='tag_cat'>
  //                 <div className='tag_lst'>
  //                   <span className='text-white my-1 font16'>{t('store-list.theme.Tags')} :&nbsp;</span>
  //                   <div className='tag_list cattag_lst'>
  //                     <span>{t('store-list.theme.Plugin')}</span>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           ) : (
  //             ''
  //           )}
  //         </div>

  //         <div
  //           className={
  //             view === 'grid'
  //               ? 'pricesection'
  //               : 'pricesection col-md-6 col-12 col-sm-12 col-xl-3 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center'
  //           }
  //         >
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>
  //               {/* {renderRating()} */}

  //         <Rating initialValue={theme.rating/20} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" />
  //             </div>
  //             <div className='col-12 col-md-6 align-self-end'>
  //               <div className='plugin_select_btn'>
  //                 <button
  //                   className='cblue_btn ms-auto'
  //                   type='button'
  //                   style={{ zIndex: 9999999999999 }}
  //                   onClick={e => onAddPlugin(plugin, e)}
  //                 >
  //                   {isSelectedActive ? 'Remove' : 'Select'}
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  <>

    {/* grid view  */}
    {view === 'grid' && (
        <div
        key={theme.id}
          className={'col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns'} onClick={()=>NavigateTo(theme)}
        >
          <div className='plug_grid'>
            <div className='card-body'>
              <div className={view === 'grid' ? 'plugin_area' : 'plugin_area row g-3 '}>
                <div
                  className={
                    view === 'grid' ? 'plug_img' : 'plug_img col-sm-12 col-md-6 col-12  col-xl-3 order-md-0 order-xl-0'
                  }
                >
                  <div className={`product_like ${isFavorite ? 'favorite' : ''}`}  onClick={(e)=>{
                    e.stopPropagation()
                    addToWishlist()}}>
                    <FontAwesomeIcon icon={faHeart}  style={{color:'white'}} size='lg'/>
                  </div>
                  <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} onError={() => handleImageError()} alt=''></img>
                </div>
                <div
                  className={view === 'grid' ? 'list' : 'list col-md-12 col-12 col-sm-12 col-xl-6 order-md-2 order-xl-1'}
                >
                  {/* <Link to={`/store/${theme.slug}`} style={{ textDecoration: 'none' }}> */}
                    <div className='pluginname font20'>{theme.name ? theme.name : theme.slug}</div>
                  {/* </Link> */}
                  <div className='author font16'>{t('store-list.plugin.By')}: {theme.wp_author}</div>
                  {Object.prototype.hasOwnProperty.call(theme,"last_updated") && theme.last_updated !==null &&
                    <div className='up_date font16'>
                    <span className='text-white font16'>{t('store-list.plugin.Last-updated')} : </span>{' '}
                    {theme.last_updated}
                  </div>
                  }
                  {/* {view === 'list' ? (
                    <div className='list_visible font16'>
                      <div className='plug_desc font16 grey-color'>
                        <span className='text-white'>{t('store-list.plugin.Compatibility')} : </span>
                        Lorem Ipsum is simply dummy text of the
                        printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                        since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book.
                      </div>
                      <div className='tag_cat'>
                        <div className='tag_lst'>
                          <span className='text-white my-1 font16'>{t('store-list.plugin.Tags')} :&nbsp;</span>
                          <div className='tag_list cattag_lst'>
                            <span>{t('store-list.plugin.Plugin')}</span>
                          </div>
                        </div>
                        <div className='categories_lst'>
                          <span className='text-white my-1 font16'>{t('store-list.plugin.Categories')}:&nbsp;</span>
                          <div className='cat_list cattag_lst'>
                            <span>{t('store-list.plugin.Ecommerce')}</span>
                            <span>{t('store-list.plugin.SEO')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                </div>

                <div
                  className={
                    view === 'grid'
                      ? 'pricesection'
                      : 'pricesection col-md-6 col-12 col-sm-12 col-xl-3 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center'
                  }
                >
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      {/* {view==='list'&&(
                        <div className="price">${plugin.rating}</div>
                      )} */}
                      {renderRating()}
                      {/* <div className="price">${plugin.rating}</div>
                <Rating initialValue={plugin.rating/20} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" /> */}
                    </div>
                    <div className='col-12 col-md-6 align-self-end'>
                      <div className='plugin_select_btn '>
                        {/* {active !== null ? (
                  <button className={active ? 'orange_btn ms-auto' : 'cblue_btn ms-auto'}
                    onClick={(e: any) => {
                      if (active) addremove(plugin, e);
                      else addremove({...plugin,projects:existinProject}, e);
                    }}
                  >
                    {active ? <span>Remove</span> : <span>Select</span>}
                  </button>
                ) : null} */}
                        {/* <button className="cblue_btn ms-auto" type="button" disabled={queryString.get("project_id") !== null ? queryString.get("project_id") !== ""?false:true:true} onClick={(e) => onAddPlugin(plugin,e)}>
                  {isSelectedActive ? "Remove":"Select"}
                </button> */}
                        {/* <button
                          className='cblue_btn ms-auto'
                          type='button'
                          style={{ zIndex: 9999999999999 }}
                          onClick={e => onAddPlugin(plugin, e)}
                        >
                          {isSelectedActive ?  `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )}



     {/* list View */}
    {view === 'list' && (
        <div style={{padding:'0px 20px', margin:'0px 0px 30px'}} onClick={()=>NavigateTo(theme)}>
          <div className="plug_grid card list_view_store">

          <div className="card-body p-0">
            <div className="plugin_area row">
              <div
                className="plug_img col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-md-0 order-xl-0 m-0"
              >
                <div className="product_image_dec">
                  <div className="product_image">
                  <div className={`product_like ${isFavorite ? 'favorite' : ''}`} style={{width:'30px', height:'30px'}}  onClick={(e)=>{
                    e.stopPropagation()
                    addToWishlist()}}>
                    <FontAwesomeIcon icon={faHeart}  style={{color:'white'}}/>
                  </div>
                    <img className='store-item-image img-fluid'  src={src} onError={() => handleImageError()} alt=''></img>
                  </div>
                  <div className="product_description">
                      {/* <Link to={`/store/${theme.slug}`} style={{ textDecoration: 'none' }}> */}
                    <div className='pluginname font20'>{theme.name ? theme.name : theme.slug}</div>
                  {/* </Link> */}
                  <div className='author font16'>{t('store-list.plugin.By')}: {theme.wp_author}</div>
                  <div aria-disabled="true" className="star_rating">
                  {renderRating()}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="list col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-2 order-xl-1 m-0"
              >
                <div className='list_tags_updates'>
                {Object.prototype.hasOwnProperty.call(theme,"last_updated") && theme.last_updated !==null &&
                    <div className='up_date font16'>
                    <span className='text-white font16'>{t('store-list.plugin.Last-updated')} : </span>{' '}
                    {theme.last_updated}
                  </div>
                  }
                <div className="list_visible font16">
                  <div className="tag_cat m-0">
                    <div className="tag_lst m-0">
                    <span className='text-white my-1 font16'>{t('store-list.plugin.Tags')} :&nbsp;</span>
                      <div className="tag_list cattag_lst"> <span>{t('store-list.plugin.Plugin')}</span></div>

                    </div>
                  </div>
                </div>
                </div>
              </div>

              <div
                className="pricesection col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center m-0"
              >
                <div className="plugin_select_btn d-flex flex-column">
                {/* <div className="price">${theme.rating}</div> */}
                {/* <button
                          className='cblue_btn ms-auto'
                          type='button'
                          style={{ zIndex: 9999999999999 }}
                          onClick={e => onAddPlugin(plugin, e)}
                        >
                          {isSelectedActive ?  `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
                        </button> */}
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
    )}
  </>
  );
}
export default StoreTheme;
